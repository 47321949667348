.text {
	
	background: -webkit-linear-gradient(rgb(10,155,234), rgb(0,61,103));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  }
  .text1 {
	
	background: -webkit-linear-gradient(rgb(10,155,234), rgb(0,61,103));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  }
 
  ::-webkit-input-placeholder {
	background: -webkit-linear-gradient(rgb(10,155,234), rgb(0,61,103));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  }
 