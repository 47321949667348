.App {

  overflow-x: hidden
}




.ant-table-thead .ant-table-cell {
  /* header color table */
  background-color: #EEEEEE;
  border: solid 1px #DDDDDD !important
}

.ant-table-content {
  /* content color table */
  background-color: #FFFFFF;
}

.ant-table-tbody>tr>td {
  /* màu border table */
  border: solid 1px #DDDDDD !important
}



.App-link {
  color: #61dafb;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.ant-dropdown-menu-item:hover {
  background: #367AA7!important;
}

.message-list {
  width: auto;

}

.rce-container-mlist message-list {
  font-family: 'Courier New', Courier, monospace;
  font-size: xx-small;
  width: 500px;
}

.rce-mbox {
  height: 70px;
}

.rce-mbox-title {
  font-size: 11px !important;
}

.rce-mbox-text {
  font-size: 10px !important;
}

.rce-mlist {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: visible;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 10px 20px 0px 50px;
  padding: 0px -10px 2px 0px;
}
.logo {
  text-decoration: none;
  color: inherit;
  margin: -10px 80px 0px -70px;
  padding: 0px -10px 2px 0px;
}
/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 13px;
  color: white;
  padding-top: 6px;
  font-weight: 700;
  text-shadow: 1px 1px black
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  text-shadow: 1px 1px black
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  /* khoảng cách các menu */
  margin-left: 6px;
}
.desktop-nav .menu-items>a,
.desktop-nav .menu-items button {
  padding: 0.4rem 0.6rem;
}
.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  color: #F89810;
}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: 0px;
  left: auto;
  color: white;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 13rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #367AA7;
  border-radius: 0.5rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.mobile-nav {
  display: none;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .nav-area {
    justify-content: space-between;
  }

  .badge {
    display: none;
  }
  .cartLink{
    margin-top: 10px;
  }
  .desktop-nav {
    display: none;
    color: white;
    text-align: right;
  }

  .mobile-nav {
    display: block;
    color: white;
  }

  .mobile-nav .mobile-nav__menu-button {
    color: inherit;
    font-size: inherit;
    text-align: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    top: -8px;
    padding-left:15px
  }

  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 50px;
    right: 10px;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #367AA7;
    border-radius: 0.5rem;
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    
  }

  .mobile-nav .menu-items>a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    color: #F89810;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }
}

/* page content */
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h1 {
  font-size: 2rem;
}
.buttonSlide:hover{
  background-color: #F89810 !important;
  color: white 
}
.titleSide:hover{
  color: white !important ;
}
#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}
