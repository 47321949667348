.is-sticky {
  position: fixed;
  top: 10;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

.menu {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  border-bottom: 1px solid #eee;
  
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
  
  .item {
    padding: 18px 28px;
    cursor: pointer;
    transition: color .3s ease-out;
    text-decoration: none;
    color: #111;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 12px;
    margin: 5px 0;
    
    &.active {
      color: #fff;
    }
  }
  
  .indicator {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 30px;
  }
}